import * as React from 'react';

interface IContext {
  visibleColumns: boolean[];
  setVisibleColumns: (visibleColumns: boolean[]) => void;
}
const ValmetTableSettingsContext = React.createContext<IContext | null>(null);

export function useValmetTableSettings(): IContext {
  const context = React.useContext<IContext>(
    ValmetTableSettingsContext as React.Context<IContext>,
  );
  if (!context) {
    throw new Error(
      `useValmetTabelSettings must be used within a ValmetTableSettingsProvider`,
    );
  }
  return context;
}

interface IProvider {
  children: React.ReactNode;
}

export function ValmetTableSettingsProvider(props: IProvider) {
  const [visibleColumns, setVisibleColumns] = React.useState<boolean[]>([]);

  const value = {
    visibleColumns,
    setVisibleColumns,
  };

  return (
    <ValmetTableSettingsContext.Provider value={value}>
      {props.children}
    </ValmetTableSettingsContext.Provider>
  );
}
