import React, {useEffect, useRef, useState} from 'react';
import {Checkbox, FormControlLabel, makeStyles, Theme} from '@material-ui/core';
import InputWrapper from './InputWrapper';
import classNames from 'classnames';
import ValmetIcon from '../ValmetIcon';
import colors from '../../configs/colors';
import {getIn, useFormikContext} from 'formik';
const {blue2, grey10, grey11, grey5, white} = colors;
export interface Props {
  isChecked: boolean;
  label: string;
  name: string;
  isDisabled?: boolean;
  size?: 'normal' | 'small';
  disableInputWrapper?: boolean;
  /**
   * If true, removes the margins on the wrapper element.
   */
  disableInputWrapperMargin?: boolean;
  tabIndex?: number;
  onChange: (checked: boolean) => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  labelRoot: {
    maxWidth: '100%',
    '&:hover .checkbox-icon': {
      borderColor: props =>
        props.isDisabled ? grey10 : props.isChecked ? blue2 : grey5,
    },
  },
  labelLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: props => (props.size === 'small' ? '11px' : '13px'),
    lineHeight: props => (props.size === 'small' ? '12px' : '16px'),
  },
  labelLabelContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  checkbox: {
    '&.Mui-focusVisible .checkbox-icon': {
      borderColor: props => (props.isDisabled ? grey11 : blue2),
    },
  },
}));

const ValmetCheckbox = (props: Props) => {
  const classes = useStyles(props);
  const labelLabelContentRef = useRef<HTMLSpanElement>(null!);
  const [isLabelContentOverflowing, setLabelContentOverflowing] =
    useState(false);

  useEffect(() => {
    const span = labelLabelContentRef.current;
    if (!span) {
      // Null sanity check, should never happen
      // But we don't want a crash in that case
      return;
    }

    setLabelContentOverflowing(span.scrollWidth > span.offsetWidth);
  }, []);

  const onChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    props.onChange(checked);
  };

  const content = (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.isChecked}
          name={props.name}
          disabled={props.isDisabled}
          onChange={onChange}
          disableRipple
          checkedIcon={
            <Icon
              isChecked
              className="checkbox-icon"
              isDisabled={props.isDisabled}
              size={props.size}
            />
          }
          icon={
            <Icon
              isChecked={false}
              className="checkbox-icon"
              isDisabled={props.isDisabled}
              size={props.size}
            />
          }
          className={classes.checkbox}
          tabIndex={props.tabIndex}
        />
      }
      label={
        <span
          ref={labelLabelContentRef}
          className={classes.labelLabelContent}
          title={isLabelContentOverflowing ? props.label : undefined}
        >
          {props.label}
        </span>
      }
      classes={{
        root: classes.labelRoot,
        label: classes.labelLabel,
      }}
    />
  );

  return props.disableInputWrapper ? (
    content
  ) : (
    <InputWrapper
      hasErrorText={false}
      hasHelpText={false}
      disableMargin={props.disableInputWrapperMargin}
    >
      {content}
    </InputWrapper>
  );
};

export default ValmetCheckbox;

interface IconProps {
  className: string;
  isChecked: boolean;
  isDisabled?: boolean;
  size?: 'normal' | 'small';
}

const useIconStyles = makeStyles<Theme, IconProps>(() => ({
  root: {
    boxSizing: 'border-box',
    borderRadius: '2px',
    width: props => (props.size === 'small' ? '13.5px' : '18px'),
    height: props => (props.size === 'small' ? '13.5px' : '18px'),
    border: props =>
      `2px solid ${
        props.isDisabled ? grey10 : props.isChecked ? blue2 : grey11
      }`,
    backgroundColor: props =>
      props.isChecked ? (props.isDisabled ? grey10 : blue2) : 'initial',
  },
  icon: {
    display: 'block',
    boxSizing: 'content-box',
    width: props => (props.size === 'small' ? '9px' : '15px'),
    height: props => (props.size === 'small' ? '9px' : '15px'),
    fontSize: props => (props.size === 'small' ? '8px' : '10px'),
    paddingTop: props => (props.size === 'small' ? '1.5px' : '2.5px'),
    paddingLeft: props => (props.size === 'small' ? '1px' : '0'),
    '& > *': {
      display: 'block',
    },
  },
}));

const Icon = (props: IconProps) => {
  const classes = useIconStyles(props);
  return (
    <div className={classNames(props.className, classes.root)}>
      {props.isChecked && (
        <ValmetIcon icon="check" className={classes.icon} color={white} />
      )}
    </div>
  );
};

export const FormikValmetCheckbox = (
  props: {formProperty: string} & Omit<
    Props,
    'isChecked' | 'name' | 'onChange'
  >,
) => {
  const {formProperty, ...rest} = props;
  const {values, setFieldValue, setFieldTouched} = useFormikContext<any>();
  const fieldValue = getIn(values, formProperty) as boolean;

  const onChange = (checked: boolean) => {
    setFieldValue(formProperty, checked);
    setFieldTouched(formProperty);
  };

  return (
    <ValmetCheckbox
      {...rest}
      name={formProperty}
      onChange={onChange}
      isChecked={fieldValue}
    />
  );
};
