import {Chip} from '@material-ui/core';
import * as React from 'react';
import ValmetIcon from '../ValmetIcon';

export interface Props {
  label: string;
  disabled?: boolean;
  isHidden?: boolean;
  onDelete: React.EventHandler<any>;
}

const ValmetChip = (props: Props) => {
  const {isHidden, onDelete: onDeleteProp, ...rest} = props;

  const onDelete: Props['onDelete'] = ev => {
    if (props.disabled) {
      return;
    }

    onDeleteProp(ev);
  };

  if (isHidden) {
    return null;
  }

  return (
    <Chip
      {...rest}
      onDelete={onDelete}
      deleteIcon={<ValmetIcon icon="close-circle" />}
    />
  );
};

export default ValmetChip;
