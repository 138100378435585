import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {makeStyles, Theme} from '@material-ui/core';
import BottomActions, {BottomAction} from './BottomActions';
import Title from './Title';
import TopActions, {TopAction} from './TopActions';

export interface Props {
  /**
   * If true, the panel will be open
   */
  open: boolean;
  /**
   * Translation key for the title shown at the top
   */
  titleTranslationKey: string;
  /**
   * Optional suffix for the title shown at the top
   */
  titleSuffix?: string;
  /**
   * Optional content that will be shown to the right
   * side of the title text.
   */
  additionalContentAfterTitleText?: React.ReactNode;
  /**
   * Action buttons to show on the bottom (e.g. Next/Cancel)
   */
  bottomActions: readonly BottomAction[];
  /**
   * Width of the panel
   */
  size: 'small' | 'medium' | 'large';
  /**
   * Action buttons to show at the top (e.g. Edit/Delete)
   */
  topActions?: readonly TopAction[];
  /**
   * Ref to the Paper element within the Drawer.
   */
  paperRef?: React.Ref<Element>;
  onCloseClick: () => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  drawerPaper: {
    width: props => {
      switch (props.size) {
        case 'large':
          return '1200px';
        case 'medium':
          return '800px';
        case 'small':
          return '400px';
      }
    },
    maxWidth: '100vw',
    height: 'calc(100vh - 70px)',
    top: '70px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  content: {
    flexGrow: 1,
    borderTop: '1px solid #D2D2D2',
    borderBottom: '1px solid #D2D2D2',
    overflowX: 'auto',
    overflowY: 'auto',
    paddingTop: '28px',
    paddingBottom: '28px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));

const ContextPanel: React.FC<Props> = props => {
  const classes = useStyles(props);
  return (
    <Drawer
      open={props.open}
      anchor="right"
      classes={{paper: classes.drawerPaper}}
      PaperProps={{
        ref: props.paperRef,
      }}
    >
      <Title
        titleTranslationKey={props.titleTranslationKey}
        titleSuffix={props.titleSuffix}
        additionalContentAfterTitleText={props.additionalContentAfterTitleText}
        onCloseClick={props.onCloseClick}
        isPreviousPageLinkVisible={false}
        onPreviousPageLinkClick={() => undefined}
      />
      <TopActions actions={props.topActions} />
      <div className={classes.content}>{props.children}</div>
      <BottomActions actions={props.bottomActions} />
    </Drawer>
  );
};

export default ContextPanel;
