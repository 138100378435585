import React, {useState} from 'react';
import IconNames from '../ValmetIcon/IconNames';
import CellBase from './CellBase';
import ValmetIcon from '../ValmetIcon';
import {
  makeStyles,
  Theme,
  Menu,
  MenuItem,
  Typography,
  Popover,
  Tooltip,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import colors from '../../configs/colors';
import {getRowBackgroundColor} from './colors';
const {black, grey3, grey10} = colors;
export interface RowAction {
  translationKey: string;
  icon: IconNames;
  color?: string;
  onClick: () => void;
}

interface Props {
  actions: readonly RowAction[];
  actionsMenuMode: 'inline' | 'popup';
  actionsMenuNoItemsTooltip?: string;
  isOddRow: boolean;
  isRowHovered: boolean;
  rowHeight: number;
  setIsRowHovered: (isHovered: boolean) => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    height: '100%',
    width: '100%',
    paddingLeft: '11px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #F1F1F3',
    cursor: props => (props.actions.length > 0 ? 'pointer' : 'initial'),
    backgroundColor: props => getRowBackgroundColor(props.isOddRow),
    boxShadow: props =>
      props.isRowHovered ? '0px 3px 6px rgba(0, 0, 0, 0.15)' : 'none',
    zIndex: props => (props.isRowHovered ? 1 : 0),
    '& > .icon': {
      transform: 'rotate(90deg)',
    },
  },
}));

const ActionsCell = (props: Props) => {
  const {actions, actionsMenuMode: mode, ...rest} = props;
  const classes = useStyles(props);
  const {t} = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] =
    useState<(EventTarget & HTMLDivElement) | null>(null);
  const [inlineAnchorEl, setInlineAnchorEl] =
    useState<(EventTarget & HTMLDivElement) | null>(null);

  const onMouseEnter = () => {
    props.setIsRowHovered(true);
  };
  const onMouseLeave = () => {
    props.setIsRowHovered(false);
  };
  const onMenuButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (actions.length === 0) {
      // There are no items in menu anyway
      return;
    }

    if (mode === 'popup') {
      setMenuAnchorEl(e.currentTarget);
    } else if (mode === 'inline') {
      setInlineAnchorEl(e.currentTarget);
    }
  };

  const tooltip =
    actions.length > 0
      ? t('table.tooltips.rowActions')
      : props.actionsMenuNoItemsTooltip;
  const disableTooltip =
    actions.length === 0 && props.actionsMenuNoItemsTooltip === undefined;

  return (
    <CellBase {...rest}>
      <Tooltip
        title={tooltip ?? ''}
        placement="bottom"
        disableFocusListener={disableTooltip}
        disableHoverListener={disableTooltip}
        disableTouchListener={disableTooltip}
      >
        <div
          className={classes.root}
          onClick={onMenuButtonClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          aria-controls="menu-row"
          aria-haspopup={actions.length > 0}
        >
          <ValmetIcon
            icon="more"
            color={actions.length === 0 ? grey10 : black}
          />
        </div>
      </Tooltip>

      <PopupMenu
        actions={props.actions}
        anchorEl={menuAnchorEl}
        setAnchorEl={setMenuAnchorEl}
      />
      <InlineMenu
        actions={props.actions}
        anchorEl={inlineAnchorEl}
        isOddRow={props.isOddRow}
        rowHeight={props.rowHeight}
        setAnchorEl={setInlineAnchorEl}
      />
    </CellBase>
  );
};

export default ActionsCell;

const PopupMenu = ({
  actions,
  anchorEl,
  setAnchorEl,
}: {
  actions: readonly RowAction[];
  anchorEl: (EventTarget & HTMLDivElement) | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<(EventTarget & HTMLDivElement) | null>
  >;
}) => {
  const {t} = useTranslation();

  return (
    <Menu
      disableAutoFocusItem
      id="menu-row"
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      {actions.map(a => (
        <MenuItem
          key={a.translationKey}
          onClick={() => {
            a.onClick();
            setAnchorEl(null);
          }}
        >
          <Typography
            style={{
              color: a.color ?? 'unset',
            }}
            variant="body1"
          >
            {t(a.translationKey)}
          </Typography>
          <ValmetIcon icon={a.icon} color={a.color} />
        </MenuItem>
      ))}
    </Menu>
  );
};

const useInlineMenuStyles = makeStyles<
  Theme,
  {isOddRow: boolean; rowHeight: number}
>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    '& > .icon': {
      marginLeft: theme.spacing(3),
    },
    '& > .icon:first-child': {
      marginLeft: theme.spacing(1),
    },
  },
  popoverPaper: {
    border: 'none',
    backgroundColor: props => getRowBackgroundColor(props.isOddRow),
    height: props => `${props.rowHeight - 2}px`,
  },
}));

const InlineMenu = ({
  actions,
  anchorEl,
  isOddRow,
  rowHeight,
  setAnchorEl,
}: {
  actions: readonly RowAction[];
  anchorEl: (EventTarget & HTMLDivElement) | null;
  isOddRow: boolean;
  rowHeight: number;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<(EventTarget & HTMLDivElement) | null>
  >;
}) => {
  const classes = useInlineMenuStyles({isOddRow, rowHeight});
  const {t} = useTranslation();

  return (
    <Popover
      id="inline-menu-row"
      classes={{paper: classes.popoverPaper}}
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'center',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      <div className={classes.root}>
        {actions.map(a => (
          // It's necessary
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          <Tooltip key={a.translationKey} title={t(a.translationKey) as string}>
            <ValmetIcon
              icon={a.icon}
              size="medium"
              color={grey3}
              onClick={() => {
                a.onClick();
                setAnchorEl(null);
              }}
            />
          </Tooltip>
        ))}
      </div>
    </Popover>
  );
};
