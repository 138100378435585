import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Theme,
  makeStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import PrimaryButton from '../Buttons/PrimaryButton';
import Filter, {Props as FilterProps} from '../Filter';
import ValmetTable, {
  Props as TableProps,
  InitialSort,
  Row,
  ValmetTableSource,
} from '../ValmetTable';
import Pagination, {Props as PaginationProps} from '../Pagination';

export interface Props<
  TRow extends Row,
  TInitialSort extends InitialSort = undefined,
> {
  /**
   * Translation key for the title at top of page
   */
  titleTranslationKey: string;
  /**
   * Translation key for the create button
   */
  createButtonTextTranslationKey: string;
  /**
   * If true, the create button is not shown
   */
  isCreateButtonHidden?: boolean;
  /**
   * If set, the given text will be shown as an error
   */
  loadingErrorTranslationKey?: string;
  /**
   * Props for Filter component
   */
  filter: FilterProps;
  /**
   * Props for ValmetTable component
   */
  table: TableProps<TRow, TInitialSort> & {
    setVisibleColumns?: (visibleColumns: boolean[]) => void;
    visibleColumns?: boolean[];
  };
  /**
   * Props for Pagination component
   */
  pagination: PaginationProps;
  onCreateClick: () => void;
}

const useStyles = makeStyles<Theme, {}>(() => ({
  titleAndCreateButton: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '28px',
  },
  title: {
    flexGrow: 1,
    lineHeight: '26px',
    marginTop: '6px',
  },
  filter: {
    marginBottom: '20px',
  },
  table: {
    marginBottom: '70px',
  },
  pagination: {},
}));

function TablePageLayout<
  TRow extends Row,
  TInitialSort extends InitialSort = undefined,
>(props: Props<TRow, TInitialSort>) {
  const {t} = useTranslation();
  const classes = useStyles({});
  return (
    <Card>
      <CardContent>
        <div className={classes.titleAndCreateButton}>
          <Typography variant="h2" className={classes.title}>
            {t(props.titleTranslationKey)}
          </Typography>
          {!props.isCreateButtonHidden && (
            <PrimaryButton
              icon="add"
              text={t(props.createButtonTextTranslationKey)}
              onClick={props.onCreateClick}
              disableMinimumWidth
            />
          )}
        </div>
        <div className={classes.filter}>
          <Filter {...props.filter} />
        </div>
        <div className={classes.table}>
          {props.table.visibleColumns && props.table.setVisibleColumns ? (
            <ValmetTableSource
              {...props.table}
              setVisibleColumns={props.table.setVisibleColumns}
              visibleColumns={props.table.visibleColumns}
            />
          ) : (
            <ValmetTable {...props.table} />
          )}
        </div>
        <div className={classes.pagination}>
          <Pagination {...props.pagination} />
        </div>
      </CardContent>
    </Card>
  );
}

export default TablePageLayout;
