import React from 'react';
import DialogBase, {DialogButton} from './DialogBase';
import {useTranslation} from 'react-i18next';

export interface Props {
  headerText: string;
  bodyText?: string;
  isOpen: boolean;
  width: 'small' | 'medium';
  onResult: (confirmed: boolean) => void;
}

const ConfirmDialog = (props: Props) => {
  const {t} = useTranslation();

  const onClose = () => {
    props.onResult(false);
  };
  const onConfirm = () => {
    props.onResult(true);
  };
  const buttons: DialogButton[] = [
    {
      text: t('forms.confirmDialog.no'),
      type: 'default',
      onClick: onClose,
    },
    {
      text: t('forms.confirmDialog.yes'),
      type: 'ghost',
      onClick: onConfirm,
    },
  ];

  return (
    <DialogBase
      headerText={props.headerText}
      isOpen={props.isOpen}
      onClose={onClose}
      width={props.width}
      bodyText={props.bodyText}
      buttons={buttons}
    />
  );
};

export default ConfirmDialog;
