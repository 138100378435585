import React, {useMemo, useState} from 'react';
import Filter from '../Filter/index';
import ValmetChip from '../ValmetChip';
import ChipPerLineContainer from '../ValmetChip/ChipPerLineContainer';
import ValmetSelect from './ValmetSelect';
import {getIn, useFormikContext} from 'formik';
import {remove} from 'ramda';
import {StyledSpacingSmall} from '../Spacing';

export interface Option {
  text: string;
  value: string;
}

export interface Props {
  /**
   * Id of the select element
   */
  selectId: string;
  /**
   * Options available to the user
   */
  options: Option[];
  /**
   * Currently selected options
   */
  selectedOptions: readonly Option[];
  /**
   * Translation key for the help text below the select
   */
  helpTextTranslationKey?: string;
  /**
   * If specified, the error message is shown
   */
  errorMessageTranslationKey?: string;
  /**
   * Translation key for the select placeholder text
   */
  selectPlaceholderTranslationKey?: string;
  /**
   * If true, disables the chips and select
   */
  isDisabled?: boolean;
  onChange: (selectedOptions: Option[]) => void;
  onSelectBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
}

const isChipHidden = (chipText: string, filterText: string) => {
  if (!filterText) {
    return false;
  }

  return !chipText.toLowerCase().includes(filterText.toLowerCase());
};

const ValmetComplexMultiSelect = (props: Props) => {
  const [filterText, setFilterText] = useState('');

  const unselectedOptions = useMemo(
    () =>
      props.options.filter(
        o => !props.selectedOptions.some(option => option.value === o.value),
      ),
    [props.options, props.selectedOptions],
  );

  const onSelectChange = (option: Option | null) => {
    if (option === null) {
      return;
    }

    props.onChange(props.selectedOptions.concat(option));
    setFilterText('');
  };

  const onSelectedValueRemove = (index: number) => () => {
    props.onChange(remove(index, 1, props.selectedOptions));
  };

  return (
    <>
      <Filter filterText={filterText} onFilterTextChange={setFilterText} />
      <StyledSpacingSmall />
      <ChipPerLineContainer>
        {props.selectedOptions.map((c, i) => (
          <ValmetChip
            key={c.value}
            label={c.text}
            disabled={props.isDisabled}
            isHidden={isChipHidden(c.text, filterText)}
            onDelete={onSelectedValueRemove(i)}
          />
        ))}
      </ChipPerLineContainer>
      <ValmetSelect
        id={props.selectId}
        options={unselectedOptions}
        onChange={onSelectChange}
        onBlur={props.onSelectBlur}
        value={null}
        isDisabled={props.isDisabled}
        errorMessageTranslationKey={props.errorMessageTranslationKey}
        helpTextTranslationKey={props.helpTextTranslationKey}
        labelTranslationKey=""
        placeholderTranslationKey={props.selectPlaceholderTranslationKey}
        clearInputOnSelect
      />
    </>
  );
};

export default ValmetComplexMultiSelect;

export const FormikValmetComplexMultiSelect = (
  props: {formProperty: string} & Omit<
    Props,
    'errorMessageTranslationKey' | 'selectedOptions' | 'selectId' | 'onChange'
  >,
) => {
  const {formProperty, ...rest} = props;
  const {touched, errors, values, setFieldValue, setFieldTouched} =
    useFormikContext<any>();
  const fieldTouched = getIn(touched, formProperty) as boolean | undefined;
  const fieldError = getIn(errors, formProperty) as string | undefined;
  const fieldValues = getIn(values, formProperty) as Option[];

  const onChange = (selectedOptions: Option[]) => {
    setFieldValue(formProperty, selectedOptions);
    setFieldTouched(formProperty);
  };

  return (
    <ValmetComplexMultiSelect
      {...rest}
      selectId={formProperty}
      errorMessageTranslationKey={fieldTouched ? fieldError : undefined}
      selectedOptions={fieldValues}
      onChange={onChange}
    />
  );
};
