import {makeStyles, Theme} from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles<Theme, {}>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

/**
 * Set ValmetChips as children of this to arrange them one per line.
 */
const ChipPerLineContainer: React.FC<{}> = props => {
  const classes = useStyles({});
  return <div className={classes.root}>{props.children}</div>;
};

export default ChipPerLineContainer;
