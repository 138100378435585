import React, {useState, useEffect} from 'react';
import {useTheme} from '@material-ui/core';
import ValmetIcon from '../ValmetIcon';
import ValmetInputBase from './ValmetInputBase';
import InputWrapper from './InputWrapper';
import {getIn, useFormikContext} from 'formik';

export interface Props {
  /**
   * Id of the element, used to connect the label
   */
  id: string;
  /**
   * Translation key for the label
   */
  labelTranslationKey: string;
  /**
   * If true, removes the margins on the wrapper element.
   */
  disableInputWrapperMargin?: boolean;
  /**
   * Translation key for the help text below the field
   */
  helpTextTranslationKey?: string;
  /**
   * If specified, the error message is shown.
   * Don't use with validator.
   */
  errorMessageTranslationKey?: string;
  /**
   * If true, displays the field as required (does not do validation, that you need to do in validator)
   */
  isRequired?: boolean;
  /**
   * Adds an (optional) text after the label text.
   * Don't use with isRequired.
   */
  isOptional?: boolean;
  isDisabled?: boolean;
  /**
   * The current value; if undefined the field will be uncontrolled
   */
  value?: string;
  width?: 'default' | 'medium' | 'small' | 'full';
  /**
   * Validation function for the current value, called at mount and every time value changes.
   * Must return either true to indicate valid data or a translation key for an error message.
   */
  validator?: (value: string) => true | string;
  onChange: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  unit?: string;
}

const ValmetTextInput = (props: Props) => {
  const theme = useTheme();
  const [errorMessageTranslationKey, setErrorMessageTranslationKey] =
    useState<string | null>(null);
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    if (props.validator === undefined) {
      return;
    }

    const value = props.value !== undefined ? props.value : internalValue;
    const result = props.validator(value);
    if (result === true) {
      setErrorMessageTranslationKey(null);
      return;
    }

    setErrorMessageTranslationKey(result);
  }, [props, props.validator, props.value, internalValue]);

  const onValueChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const updatedValue = ev.target.value;
    setInternalValue(updatedValue);
    props.onChange(updatedValue);
  };

  return (
    <InputWrapper
      width={props.width}
      hasErrorText={
        Boolean(errorMessageTranslationKey) ||
        Boolean(props.errorMessageTranslationKey)
      }
      hasHelpText={Boolean(props.helpTextTranslationKey)}
      disableMargin={props.disableInputWrapperMargin}
    >
      <ValmetInputBase
        labelTranslationKey={props.labelTranslationKey}
        errorMessageTranslationKey={
          props.errorMessageTranslationKey ?? errorMessageTranslationKey
        }
        helpTextTranslationKey={props.helpTextTranslationKey}
        isRequired={props.isRequired}
        isOptional={props.isOptional}
        width={props.width}
        InputBaseProps={{
          id: props.id,
          value: props.value !== undefined ? props.value : internalValue,
          onChange: onValueChange,
          disabled: props.isDisabled,
          endAdornment:
            Boolean(errorMessageTranslationKey) ||
            Boolean(props.errorMessageTranslationKey) ? (
              <ValmetIcon
                icon="dialog-error"
                color={theme.palette.error.main}
              />
            ) : props.unit ? (
              <span className="icon unitMark">{props.unit}</span>
            ) : undefined,
          onBlur: props.onBlur,
        }}
      />
    </InputWrapper>
  );
};

export default ValmetTextInput;

export const FormikValmetTextInput = (
  props: {formProperty: string} & Omit<
    Props,
    'errorMessageTranslationKey' | 'value' | 'validator' | 'onChange' | 'onBlur'
  >,
) => {
  const {formProperty, ...rest} = props;
  const {touched, errors, values, setFieldValue, setFieldTouched} =
    useFormikContext<any>();
  const fieldTouched = getIn(touched, formProperty) as boolean | undefined;
  const fieldError = getIn(errors, formProperty) as string | undefined;
  const fieldValue = getIn(values, formProperty) as string;

  const onChange = (val: string) => {
    setFieldValue(formProperty, val);
  };
  const onBlur = () => {
    setFieldTouched(formProperty);
  };

  return (
    <ValmetTextInput
      {...rest}
      value={fieldValue}
      errorMessageTranslationKey={fieldTouched ? fieldError : undefined}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
