import React from 'react';
import {StyledComponentProps, withStyles, Theme} from '@material-ui/core';

const styles = (theme: Theme) => ({
  spacingSmall: {
    margin: theme.spacing(1, 0),
  },
  spacing: {
    margin: theme.spacing(2, 0),
  },
  spacingLarge: {
    margin: theme.spacing(4, 0),
  },
});

const Spacing: (props: StyledComponentProps) => JSX.Element = ({classes}) => (
  <div className={classes?.spacing} />
);
const SpacingLarge: (props: StyledComponentProps) => JSX.Element = ({
  classes,
}) => <div className={classes?.spacingLarge} />;
const SpacingSmall: (props: StyledComponentProps) => JSX.Element = ({
  classes,
}) => <div className={classes?.spacingSmall} />;

const StyledSpacing = withStyles(styles)(Spacing);
const StyledSpacingLarge = withStyles(styles)(SpacingLarge);
const StyledSpacingSmall = withStyles(styles)(SpacingSmall);

export {StyledSpacing, StyledSpacingLarge, StyledSpacingSmall};
