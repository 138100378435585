import {Theme} from '@material-ui/core';
import colors from '../colors';
const {grey6, grey3, grey10, grey8} = colors;
export default (theme: Theme) => {
  return {
    MuiInputBase: {
      root: {
        display: 'flex',
        marginTop: theme.spacing(0.5),
      },
      input: {
        borderRadius: '2px',
        border: `1px solid ${grey6}`,
        padding: theme.spacing(1, 1.5),
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '14px',
        color: grey3,
        height: '32px',
        boxSizing: 'border-box',
        '&::selection': {
          backgroundColor: '#B9D1FC',
        },
        '&:hover': {
          border: `1px solid ${grey3}`,
        },
        '&:focus': {
          border: '2px solid #018ABA !important',
          padding: '7px 11px !important',
        },
        '.Mui-error > &': {
          border: `1px solid ${theme.palette.error.main}`,
        },
        '.Mui-error > &:focus': {
          border: `1px solid ${theme.palette.error.main} !important`,
          padding: `${theme.spacing(1, 1.5)} !important`,
        },
        '.Mui-disabled > &': {
          border: `1px solid ${grey10}`,
          backgroundColor: '#F1F1F1',
          color: grey8,
        },
      },
      multiline: {
        padding: 0,
      },
      inputMultiline: {
        padding: theme.spacing(1, 1.5),
      },
      adornedStart: {
        '& > .icon:first-child': {
          fontSize: '18px',
          lineHeight: '18px',
          position: 'absolute',
          top: '12px',
          left: '12px',
        },
        '& > .MuiInputBase-input': {
          paddingLeft: '42px', // 12px regular padding + 18px icon width + 12px padding from icon
        },
        '& > .MuiInputBase-input:focus': {
          paddingLeft: '42px !important',
        },
        '&.Mui-error > .MuiInputBase-input:focus': {
          paddingLeft: '42px !important',
        },
      },
      adornedEnd: {
        '& > .icon:last-child': {
          fontSize: '18px',
          lineHeight: '18px',
          position: 'absolute',
          top: '12px',
          right: '12px',
        },
        '& > .MuiInputBase-input': {
          paddingRight: '42px', // 12px regular padding + 18px icon width + 12px padding from icon
        },
        '& > .MuiInputBase-input:focus': {
          paddingRight: '42px !important',
        },
        '&.Mui-error > .MuiInputBase-input:focus': {
          paddingRight: '42px !important',
        },
      },
    },
  };
};
