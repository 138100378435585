import React, {useEffect, useRef, useState} from 'react';
import {
  InputBaseComponentProps,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import InputWrapper, {InputWidth, Widths} from './InputWrapper';
import ValmetInputBase from './ValmetInputBase';
import ValmetIcon from '../ValmetIcon';
import colors from '../../configs/colors';
import classNames from 'classnames';
import MultiSelectMenu from '../Menus/MultiSelectMenu';
import {indexOf, remove} from 'ramda';
import {getIn, useFormikContext} from 'formik';
const {black, blue2, grey10, white} = colors;
export interface Option {
  text: string;
  value: string;
}

export interface Props {
  /**
   * Id of the element, used to connect the label
   */
  id: string;
  /**
   * Translation key for the label
   */
  labelTranslationKey?: string;
  /**
   * Translation key for the help text below the select
   */
  helpTextTranslationKey?: string;
  /**
   * If specified, the error message is shown.
   * Don't use with validator.
   */
  errorMessageTranslationKey?: string;
  /**
   * If true, displays the field as required (does not do validation, that you need to do in validator)
   */
  isRequired?: boolean;
  /**
   * Adds an (optional) text after the label text.
   * Don't use with isRequired.
   */
  isOptional?: boolean;
  /**
   * Options available to the user
   */
  options: readonly Option[];
  /**
   * The currently selected values,
   * should have reference equality to options
   */
  values: readonly Option[];
  isDisabled?: boolean;
  width?: InputWidth;
  menuContainerEl?: Element | null;
  /**
   * If true, removes the margins on the wrapper element.
   */
  disableInputWrapperMargin?: boolean;
  onChange: (values: Option[]) => void;
  /**
   * Called when the options menu is closed.
   */
  onMenuClose?: () => void;
  /**
   * Validation function for the current values, called at mount and every time values change.
   * Must return either true to indicate valid data or a translation key for an error message.
   */
  validator?: (selectedValues: readonly Option[]) => true | string;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    width: props => Widths[props.width ?? 'default'],
    maxWidth: '100%',
    '& > .icon:last-child': {
      fontSize: '12px',
      lineHeight: '12px',
      top: '10px',
    },
  },
}));

type ExpandIconClickState =
  | 'RECEIVED_MOUSEDOWN_WHILE_MENU_OPEN'
  | 'RECEIVED_MOUSEUP_AFTER_MOUSEDOWN';

const ValmetMultiSelect = (props: Props) => {
  const classes = useStyles(props);

  // The field is a div actually, but TypeScript is not happy
  // if we set this to e.g. HTMLElement
  const inputRef = useRef<HTMLInputElement>(null!);
  const [expandIconClickState, setExpandIconClickState] =
    useState<ExpandIconClickState | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  const [errorMessageTranslationKey, setErrorMessageTranslationKey] =
    useState<string | null>(null);

  const {validator: validatorProp, values: selectedValuesProp} = props;
  useEffect(() => {
    if (validatorProp === undefined) {
      return;
    }

    const result = validatorProp(selectedValuesProp);
    if (result === true) {
      setErrorMessageTranslationKey(null);
      return;
    }

    setErrorMessageTranslationKey(result);
  }, [validatorProp, selectedValuesProp]);

  const onInputFocus = () => {
    if (props.isDisabled) {
      return;
    }

    setMenuAnchorEl(inputRef.current);
  };
  const onMenuClose = () => {
    setMenuAnchorEl(null);
    if (props.onMenuClose) {
      props.onMenuClose();
    }
  };
  const onOptionRemove = (option: Option) => {
    props.onChange(remove(indexOf(option, props.values), 1, props.values));
  };

  // This is for ensuring that we don't re-open the menu if the user clicks out of the menu by clicking the expand icon
  // The icon receives events: mousedown -> mouseup -> click
  // If in mousedown the menu is open, we go to the first state, otherwise state is set to null
  // If we receive the mouseup event while in first state, we go to second state
  // If we receive a click event on the icon while in second state, we can ignore the click event entirely
  const onExpandIconMouseDown = () => {
    setExpandIconClickState(
      isMenuOpen ? 'RECEIVED_MOUSEDOWN_WHILE_MENU_OPEN' : null,
    );
  };
  const onExpandIconMouseUp = () => {
    if (expandIconClickState === 'RECEIVED_MOUSEDOWN_WHILE_MENU_OPEN') {
      setExpandIconClickState('RECEIVED_MOUSEUP_AFTER_MOUSEDOWN');
    }
  };
  const onExpandIconClick = () => {
    if (expandIconClickState !== 'RECEIVED_MOUSEUP_AFTER_MOUSEDOWN') {
      onInputFocus();
    }
  };

  return (
    <InputWrapper
      hasErrorText={
        Boolean(props.errorMessageTranslationKey) ||
        Boolean(errorMessageTranslationKey)
      }
      hasHelpText={Boolean(props.helpTextTranslationKey)}
      disableMargin={props.disableInputWrapperMargin}
      hideOverflow
      width={props.width}
    >
      <ValmetInputBase
        InputBaseProps={{
          id: props.id,
          inputComponent: Input,
          endAdornment: (
            <ValmetIcon
              icon={isMenuOpen ? 'arrow-up' : 'arrow-down'}
              color={props.isDisabled ? grey10 : black}
              onClick={onExpandIconClick}
              onMouseDown={onExpandIconMouseDown}
              onMouseUp={onExpandIconMouseUp}
            />
          ),
          onFocus: onInputFocus,
          classes: {root: classes.root},
          disabled: props.isDisabled,
        }}
        inputProps={{
          values: props.values,
          onOptionRemove,
        }}
        labelTranslationKey={props.labelTranslationKey}
        width={props.width}
        ref={inputRef}
        errorMessageTranslationKey={
          props.errorMessageTranslationKey ?? errorMessageTranslationKey
        }
        helpTextTranslationKey={props.helpTextTranslationKey}
        isRequired={props.isRequired}
        isOptional={props.isOptional}
      />
      <MultiSelectMenu
        anchorEl={menuAnchorEl}
        containerEl={props.menuContainerEl}
        placement="bottom"
        onClose={onMenuClose}
        onSelectedOptionsChange={props.onChange}
        options={props.options}
        selectedOptions={props.values}
        showFilter
        widthPixels={inputRef.current?.offsetWidth}
        offset="0, 1"
        selectedOptionsFirst
      />
    </InputWrapper>
  );
};

const useInputStyles = makeStyles<Theme, {}>(() => ({
  root: {
    height: 'unset',
    minHeight: '32px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '3px',
    // Border size changes on focus, so we gotta adjust paddings
    // The class is specified twice here to increase specificity of the rules
    '&&:focus': {
      paddingTop: '2px !important',
      paddingBottom: '2px !important',
      paddingLeft: '2px !important',
      paddingRight: '41px !important',
    },
  },
}));

const Input = (props: InputBaseComponentProps) => {
  const classes = useInputStyles({});
  const inputRef = props.inputRef as React.Ref<any>;
  const selectedOptions = (props.values as readonly Option[]) ?? [];
  const onOptionRemove = props.onOptionRemove as (option: Option) => void;
  const isDisabled = props.disabled as boolean | undefined;

  return (
    <div
      ref={inputRef}
      className={classNames(props.className, classes.root)}
      tabIndex={isDisabled ? undefined : 0}
      onFocus={props.onFocus as any}
    >
      {selectedOptions.map(o => (
        <Chip
          key={o.value}
          option={o}
          isDisabled={isDisabled}
          onRemove={onOptionRemove}
        />
      ))}
    </div>
  );
};

const useChipStyles = makeStyles<Theme, {isDisabled?: boolean}>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: props => (!props.isDisabled ? blue2 : grey10),
    border: 'none',
    borderRadius: '4px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: '24px',
    maxWidth: '100%',
  },
  text: {
    flexGrow: 1,
    flexShrink: 1,
    color: white,
    fontSize: '13px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  removeButton: {
    marginLeft: theme.spacing(1.5),
    backgroundColor: white,
    borderRadius: '6px',
    width: '12px',
    height: '12px',
    fontSize: '11px',
    textAlign: 'center',
    lineHeight: '12px',
  },
}));

export const Chip = (props: {
  option: Option;
  isDisabled?: boolean;
  onRemove: (option: Option) => void;
}) => {
  const classes = useChipStyles({
    isDisabled: props.isDisabled,
  });
  const onRemoveClick = () => {
    if (props.isDisabled) {
      return;
    }

    props.onRemove(props.option);
  };
  return (
    <div className={classes.root} title={props.option.text}>
      <Typography className={classes.text}>{props.option.text}</Typography>
      <div
        className={classes.removeButton}
        onMouseDown={ev => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
      >
        <ValmetIcon
          icon="close-circle"
          color={!props.isDisabled ? blue2 : grey10}
          disablePointerCursor={props.isDisabled}
          onClick={onRemoveClick}
        />
      </div>
    </div>
  );
};

export default ValmetMultiSelect;

export const FormikValmetMultiSelect = (
  props: {formProperty: string} & Omit<
    Props,
    | 'values'
    | 'errorMessageTranslationKey'
    | 'onChange'
    | 'validator'
    | 'onMenuClose'
  >,
) => {
  const {formProperty, ...rest} = props;
  const {touched, errors, values, setFieldValue, setFieldTouched} =
    useFormikContext<any>();
  const fieldTouched = getIn(touched, formProperty) as boolean | undefined;
  const fieldError = getIn(errors, formProperty) as string | undefined;
  const fieldValues = getIn(values, formProperty) as readonly Option[];

  const onChange = (vals: Option[]) => {
    setFieldValue(formProperty, vals);
  };
  const onMenuClose = () => {
    setFieldTouched(formProperty);
  };

  return (
    <ValmetMultiSelect
      {...rest}
      onChange={onChange}
      values={fieldValues}
      errorMessageTranslationKey={fieldTouched ? fieldError : undefined}
      onMenuClose={onMenuClose}
    />
  );
};
