import React, {useState, useEffect} from 'react';
import {Autocomplete} from '@material-ui/lab';
import {useTranslation} from 'react-i18next';
import ValmetInputBase from './ValmetInputBase';
import ValmetIcon from '../ValmetIcon';
import colors from '../../configs/colors';
import {makeStyles, Theme, Typography} from '@material-ui/core';
import InputWrapper, {InputWidth, Widths} from './InputWrapper';
import {getIn, useFormikContext} from 'formik';
const {black, blue2, grey10} = colors;
export interface Option {
  text: string;
  value: string;
}

export interface Props {
  /**
   * Id of the element, used to connect the label
   */
  id: string;
  /**
   * Options available to the user
   */
  options: Option[];
  /**
   * Translation key for the label
   */
  labelTranslationKey?: string;
  /**
   * Translation key for the help text below the select
   */
  helpTextTranslationKey?: string;
  /**
   * If specified, the error message is shown.
   * Don't use with validator.
   */
  errorMessageTranslationKey?: string;
  /**
   * If true, displays the field as required (does not do validation, that you need to do in validator)
   */
  isRequired?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  /**
   * The currently selected value; if undefined the select will be uncontrolled
   */
  value?: Option | null;
  width?: InputWidth;
  clearInputOnSelect?: boolean;
  placeholderTranslationKey?: string;
  /**
   * If true, removes the margins on the wrapper element.
   */
  disableInputWrapperMargin?: boolean;
  onChange: (selectedOption: Option | null) => void;
  /**
   * Validation function for the current value, called at mount and every time value changes.
   * Must return either true to indicate valid data or a translation key for an error message.
   */
  validator?: (selectedOption: Option | null | undefined) => true | string;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    width: props => Widths[props.width ?? 'default'],
    maxWidth: '100%',
  },
}));

const ValmetSelect = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles(props);
  const [errorMessageTranslationKey, setErrorMessageTranslationKey] =
    useState<string | null>(null);
  const [internalValue, setInternalValue] =
    useState<Option | null | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');

  const {validator: validatorProp, value: valueProp} = props;
  useEffect(() => {
    if (validatorProp === undefined) {
      return;
    }

    const value = valueProp !== undefined ? valueProp : internalValue;
    const result = validatorProp(value);
    if (result === true) {
      setErrorMessageTranslationKey(null);
      return;
    }

    setErrorMessageTranslationKey(result);
  }, [validatorProp, valueProp, internalValue]);

  const onValueChange = (_: React.ChangeEvent<{}>, value: Option | null) => {
    setInternalValue(value);
    props.onChange(value);
    if (props.clearInputOnSelect) {
      setInputValue('');
    }
  };
  const onInputChange = (_: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
  };

  return (
    <InputWrapper
      hasErrorText={
        Boolean(props.errorMessageTranslationKey) ||
        Boolean(errorMessageTranslationKey)
      }
      hasHelpText={Boolean(props.helpTextTranslationKey)}
      disableMargin={props.disableInputWrapperMargin}
      width={props.width}
    >
      <Autocomplete
        options={props.options}
        disabled={props.isDisabled}
        hidden={props.isHidden}
        getOptionLabel={option => option.text}
        getOptionSelected={(option, value) => option.value === value.value}
        onChange={onValueChange}
        value={props.value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        renderInput={inputProps => (
          <ValmetInputBase
            width={props.width}
            helpTextTranslationKey={props.helpTextTranslationKey}
            isRequired={props.isRequired}
            labelTranslationKey={props.labelTranslationKey}
            errorMessageTranslationKey={
              props.errorMessageTranslationKey ?? errorMessageTranslationKey
            }
            ref={inputProps.InputProps.ref}
            inputProps={inputProps.inputProps}
            InputBaseProps={{
              id: inputProps.id,
              disabled: inputProps.disabled,
              className: inputProps.InputProps.className,
              startAdornment: inputProps.InputProps.startAdornment,
              endAdornment: inputProps.InputProps.endAdornment,
            }}
            placeholderTranslationKey={props.placeholderTranslationKey}
          />
        )}
        renderOption={option => <SingleSelectOption option={option} />}
        classes={{
          root: classes.root,
        }}
        onBlur={props.onBlur}
        openOnFocus
        popupIcon={
          <ValmetIcon
            icon="arrow-down"
            color={props.isDisabled ? grey10 : black}
          />
        }
        closeIcon={
          <ValmetIcon
            icon="arrow-up"
            color={props.isDisabled ? grey10 : black}
          />
        }
        openText={t('select.open')}
        closeText={t('select.close')}
        clearText={t('select.clear')}
        noOptionsText={t('select.noOptions')}
        loadingText={t('select.loading')}
      />
    </InputWrapper>
  );
};

export const SingleSelectOption = ({option}: {option: Option}) => {
  return (
    <>
      <ValmetIcon icon="check" color={blue2} />
      <Typography style={{wordBreak: 'break-word'}}>{option.text}</Typography>
    </>
  );
};

export default ValmetSelect;

export const FormikValmetSelect = (
  props: {
    formProperty: string;
    spyOnChange?: (selectedOption: Option | null) => void;
  } & Omit<
    Props,
    'errorMessageTranslationKey' | 'value' | 'onChange' | 'validator' | 'onBlur'
  >,
) => {
  const {formProperty, spyOnChange, ...rest} = props;
  const {touched, errors, values, setFieldValue, setFieldTouched} =
    useFormikContext<any>();
  const fieldTouched = getIn(touched, formProperty) as boolean | undefined;
  const fieldError = getIn(errors, formProperty) as string | undefined;
  const fieldValue = getIn(values, formProperty) as Option | null;

  const onChange = (val: Option | null) => {
    setFieldValue(formProperty, val);
    spyOnChange && spyOnChange(val);
  };
  const onBlur = () => {
    setFieldTouched(formProperty);
  };

  return (
    <ValmetSelect
      {...rest}
      value={fieldValue}
      errorMessageTranslationKey={fieldTouched ? fieldError : undefined}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
