import React, {useRef, useState} from 'react';
import {makeStyles, Theme, Tooltip} from '@material-ui/core';
import HeaderCellBase from './HeaderCellBase';
import colors from '../../configs/colors';
import ValmetIcon from '../ValmetIcon';
import ColumnSettingsPopover from './ColumnSettingsPopover';
import {Column} from './types';
import {useTranslation} from 'react-i18next';
const {grey3, grey14, grey11} = colors;
interface Props {
  columns: readonly Column[];
  visibleColumns: readonly boolean[];
  setVisibleColumns: (visibleColumns: boolean[]) => void;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '44px',
    width: '100%',
    paddingLeft: '10px',
    cursor: 'pointer',
    color: grey3,
    backgroundColor: grey14,
    borderRight: `1px solid ${grey11}`,
    borderTop: `1px solid ${grey11}`,
    borderBottom: `1px solid ${grey11}`,
  },
}));

const SettingsHeaderCell = (props: Props) => {
  const classes = useStyles(props);
  const {t} = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null!);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLDivElement | null>(null);

  const handleCellClick = () => {
    setPopoverAnchorEl(rootRef.current);
  };

  return (
    <HeaderCellBase>
      <Tooltip
        title={t('table.tooltips.columnPreferences') ?? ''}
        placement="bottom"
      >
        <div className={classes.root} ref={rootRef} onClick={handleCellClick}>
          <ValmetIcon icon="settings" />
        </div>
      </Tooltip>

      <ColumnSettingsPopover
        anchorEl={popoverAnchorEl}
        columns={props.columns.map((c, i) => ({
          ...c,
          // eslint-disable-next-line security/detect-object-injection
          visible: props.visibleColumns[i],
        }))}
        onVisibleColumnsChange={props.setVisibleColumns}
        onClose={() => setPopoverAnchorEl(null)}
      />
    </HeaderCellBase>
  );
};

export default SettingsHeaderCell;
