/* eslint-disable react/jsx-key */
import React, {PropsWithChildren} from 'react';
import {useSticky} from 'react-table-sticky';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  CellProps,
  HeaderProps,
  Hooks,
  Row,
  TableInstance,
  TableOptions,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  useTable,
  UseTableOptions,
  UsePaginationState,
  UsePaginationInstanceProps,
  usePagination,
  UsePaginationOptions,
  UseRowSelectState,
  IdType,
  useSortBy,
  UseSortByColumnOptions,
  UseSortByColumnProps,
  HeaderGroup,
  UseFiltersColumnProps,
  useFilters,
  UseFiltersInstanceProps,
  UseFiltersState,
  TableState,
  UseSortByInstanceProps,
  SortingRule,
  UseGlobalFiltersState,
  useGlobalFilter,
  UseResizeColumnsColumnProps,
  useResizeColumns,
  useBlockLayout,
  useExpanded,
  useColumnOrder,
  UseColumnOrderInstanceProps,
  UseColumnOrderState,
  UseExpandedRowProps,
} from 'react-table';
import {Box, Tooltip, useTheme} from '@material-ui/core';
import {Pagination as PaginationUI} from '@material-ui/lab';
import {StyledCheckbox} from './StyledCheckbox';
import {useTableSettings} from './table-settings-context';
import colors from '../../configs/colors';
import {useStyles} from './styles';
import PageIndicator from '../Pagination/PageIndicator';
import PageSizeSelector from '../Pagination/PageSizeSelector';
import {useTranslation} from 'react-i18next';

export const STATIC_CELL_WIDTH = 35;
export const EXPANDER_CELL_ID = 'expander';
export const SETTINGS_CELL_ID = 'settings';
const SELECTION_CELL_ID = 'selection';

export type TState<T extends {}> = UsePaginationState<T> &
  UseColumnOrderState<T> &
  UseRowSelectState<T> &
  UseGlobalFiltersState<T> &
  UseFiltersState<T> & {sortBy?: SortingRule<{}>[]} & TableState<T>;

export type TInstance<T extends {}> = TableInstance<T> &
  UseRowSelectInstanceProps<T> &
  UsePaginationInstanceProps<T> &
  UseSortByInstanceProps<T> &
  UseColumnOrderInstanceProps<T> &
  UseFiltersInstanceProps<T>;
export interface TableProperties<T extends {}> extends TableOptions<T> {
  onRowSelect?: (selectedIds: IdType<T>[]) => void;
  onRowClick?: (id: string) => void;
  serverStatus?: {
    pageSize: number;
    resultCount: number;
  };
  initialState?: TState<T>;
  onChangeState?: (state: TState<T>) => void;
  instanceProvider?: (instance: TInstance<T>) => void;
  isVerticalScroll?: boolean;
  expandedContentRender?: (row: any) => React.ReactNode | null;
  autoResetPage?: boolean;
}

const selectionHook = (hooks: Hooks<any>) => {
  hooks.allColumns.push(clmns => {
    return [
      {
        id: SELECTION_CELL_ID,
        disableResizing: true,
        sticky: 'left',
        minWidth: STATIC_CELL_WIDTH,
        width: STATIC_CELL_WIDTH,
        maxWidth: STATIC_CELL_WIDTH,
        Header: ({
          getToggleAllRowsSelectedProps,
        }: HeaderProps<any> & UseRowSelectInstanceProps<any>) => {
          return <StyledCheckbox {...getToggleAllRowsSelectedProps()} />;
        },
        Cell: ({row}: CellProps<any>) => {
          return (
            <StyledCheckbox
              {...(
                row as Row<any> & UseRowSelectRowProps<any>
              ).getToggleRowSelectedProps()}
            />
          );
        },
      },
      ...clmns,
    ];
  });
};
const hooks = [
  useFilters,
  useGlobalFilter,
  useSortBy,
  useExpanded,
  usePagination,
  useBlockLayout,
  useColumnOrder,
  useResizeColumns,
  useRowSelect,
  useSticky,
];

// define scrollBar width
const SCROLL_BAR_WIDTH = (() => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer?.parentNode?.removeChild(outer);

  return scrollbarWidth;
})();

function SortArrows() {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Box position="relative" top={theme.spacing(1.5)}>
        <ExpandLessIcon fontSize="small" />
      </Box>
      <Box position="relative" bottom={theme.spacing(0.5)}>
        <ExpandMoreIcon fontSize="small" />
      </Box>
    </Box>
  );
}
function SortArrowTop() {
  return <ExpandLessIcon fontSize="small" />;
}
function SortArrowBottom() {
  return <ExpandMoreIcon fontSize="small" />;
}

export function Table<T extends {}>(
  props: PropsWithChildren<TableProperties<T>> & UseTableOptions<T>,
) {
  const staticColumnsLength = (() => {
    const staticColumnList = [EXPANDER_CELL_ID, SETTINGS_CELL_ID];
    const resultStatic = props.columns.filter(item =>
      staticColumnList.includes(item.id as string),
    ).length;
    return resultStatic;
  })();
  const columnsLength = (() => {
    if (!!staticColumnsLength) {
      return props.columns.length - staticColumnsLength;
    }
    return props.columns.length;
  })();

  const [defaultColumnConfig, setDefaultColumnConfig] =
    React.useState<{minWidth: number; width: number} | null>(null);
  const tableContainerRef = React.useCallback(
    (container: HTMLDivElement | null) => {
      if (container === null) {
        setDefaultColumnConfig(null);
        return;
      }

      const containerWidth = container.clientWidth;
      setDefaultColumnConfig({
        minWidth: 100,
        width:
          (containerWidth -
            (props.isVerticalScroll ? SCROLL_BAR_WIDTH : 0) -
            staticColumnsLength * STATIC_CELL_WIDTH) /
            columnsLength -
          (!!props.onRowSelect ? STATIC_CELL_WIDTH / columnsLength : 0),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      columnsLength,
      props.isVerticalScroll,
      props.columns,
      props.onRowSelect,
      staticColumnsLength,
    ],
  );

  return (
    <div ref={tableContainerRef}>
      {defaultColumnConfig !== null && (
        <TableInternal {...props} defaultColumnConfig={defaultColumnConfig} />
      )}
    </div>
  );
}

function debounce(func: () => void) {
  let timer: ReturnType<typeof setTimeout>;
  return function (): void {
    if (timer) clearTimeout(timer);
    // eslint-disable-next-line scanjs-rules/call_setTimeout
    timer = setTimeout(func, 100);
  };
}

function TableInternal<T extends {}>(
  props: PropsWithChildren<TableProperties<T>> &
    UseTableOptions<T> & {
      defaultColumnConfig: {minWidth: number; width: number};
    },
) {
  const {
    onRowSelect,
    serverStatus,
    onRowClick,
    initialState,
    onChangeState,
    expandedContentRender,
    defaultColumnConfig,
    autoResetPage,
    ...reactTable
  } = props;
  const {setInstance, toggleHiddenColumns, rebuildColumns} = useTableSettings();
  const classes = useStyles();
  const {t} = useTranslation();
  const initialHiddenColumns = props.columns
    .filter((item: any) => item.isVisible === false)
    .map(item => item.id || item.accessor);

  const getRowId = React.useCallback(row => {
    return row.id;
  }, []);

  let optionsFromServerStatus = {};

  if (serverStatus) {
    const {resultCount, pageSize} = serverStatus;
    optionsFromServerStatus = {
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      autoResetSelectedRows: false,
      pageCount: resultCount ? Math.ceil(resultCount / pageSize) : null,
    };
  }
  const finalHooks = props.onRowSelect ? [...hooks, selectionHook] : hooks;
  const instance = useTable<T>(
    {
      ...reactTable,
      autoResetPage,
      defaultColumn: defaultColumnConfig,
      getRowId,
      initialState:
        {...initialState, hiddenColumns: initialHiddenColumns} || {},
      ...optionsFromServerStatus,
    } as TableOptions<T> & UsePaginationOptions<T>,
    ...finalHooks,
  );
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    getTableBodyProps,
    gotoPage,
    rows,
    page,
    setPageSize,
    state,
    pageCount,
    setSortBy,
  } = instance as TInstance<T>;
  const {pageIndex, pageSize, selectedRowIds, hiddenColumns, sortBy} =
    state as TState<T> & {
      sortBy: SortingRule<{}>[];
    };
  const stringifiedState = JSON.stringify(state);
  React.useEffect(() => {
    onChangeState?.(state as TState<T>);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedState]);
  React.useEffect(() => {
    toggleHiddenColumns(hiddenColumns || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenColumns]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resizeTableFullWidth = React.useCallback(
    debounce(() => {
      // here we have 2 options to resize table on fullscreen:
      // 1 - dispatch resize hook actions depending on window.innerWidth
      // instance.dispatch({
      //   type: 'columnStartResizing',
      //   clientX: 0,
      //   headerIdWidths: [
      //     ['columnName1', widthValue],
      //     ['columnName2', widthValue],
      //   ],
      //   columnWidth: widthValue,
      //   columnId: 'columnName2',
      // });
      // instance.dispatch({
      //   type: 'columnResizing',
      //   clientX: 0,
      // });
      // instance.dispatch({
      //   type: 'columnDoneResizing',
      // });

      // OR

      // 2 - call rebuildColumns twice, the second one with setTimeout wrapper,
      // no idea  why it doesn't work with  single  call and we have to call rebuild  second time with setTimeout it just works =|
      const allColumns = instance?.allColumns || [];
      rebuildColumns(allColumns);
      // eslint-disable-next-line scanjs-rules/call_setTimeout
      setTimeout(() => {
        rebuildColumns(allColumns);
      });
    }),
    [instance],
  );
  React.useEffect(() => {
    setInstance(instance as TInstance<any>);
    // eslint-disable-next-line scanjs-rules/call_addEventListener
    window.addEventListener('resize', resizeTableFullWidth);
    return () => {
      window.removeEventListener('resize', resizeTableFullWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (onRowSelect) {
      onRowSelect(Object.keys(selectedRowIds));
    }
  }, [selectedRowIds, onRowSelect]);
  if (!sortBy.length && initialState?.sortBy?.length) {
    setSortBy(initialState.sortBy);
  }
  return (
    <Box className={`${classes.root}`}>
      <div
        {...getTableProps()}
        className={`${classes.table} ${classes.sticky}`}
        style={{maxHeight: '100%'}}
      >
        <div className={`${classes.header}`}>
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className={classes.tr}>
              {headerGroup.headers.map(group => {
                const column = group as HeaderGroup<T> &
                  UseSortByColumnProps<T> &
                  UseFiltersColumnProps<T> &
                  UseResizeColumnsColumnProps<T> &
                  UseSortByColumnOptions<T>;
                let renderedHeader: React.ReactNode = column.render('Header');
                if (typeof renderedHeader === 'string') {
                  renderedHeader = (
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        flexGrow: 1,
                      }}
                    >
                      {renderedHeader}
                    </span>
                  );
                } else {
                  renderedHeader = (
                    <div style={{flexGrow: 1}}>{renderedHeader}</div>
                  );
                }

                let sortingTooltip: string | undefined;
                if (column.canSort) {
                  if (!column.isSorted) {
                    sortingTooltip = t('table.tooltips.sortAscending');
                  } else if (!column.isSortedDesc) {
                    sortingTooltip = t('table.tooltips.sortDescending');
                  } else {
                    const defaultSortedColumnRule = initialState?.sortBy?.find(
                      rule => {
                        return rule.id === column.id;
                      },
                    );
                    if (defaultSortedColumnRule) {
                      sortingTooltip = defaultSortedColumnRule.desc
                        ? t('table.tooltips.sortDescending')
                        : t('table.tooltips.sortAscending');
                    } else {
                      sortingTooltip = t('table.tooltips.resetSort');
                    }
                  }
                }

                return (
                  <Tooltip
                    key={column.id}
                    title={sortingTooltip ?? ''}
                    placement="bottom"
                    disableHoverListener={!column.canSort}
                    disableFocusListener={!column.canSort}
                    disableTouchListener={!column.canSort}
                  >
                    <div
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          title: undefined,
                        }),
                      )}
                      className={`${classes.th} ${
                        column.id === SELECTION_CELL_ID ? classes.thSelect : ''
                      }`}
                    >
                      {renderedHeader}
                      {!column.disableSortBy && (
                        <Box display="flex" justifyContent="flex-end">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortArrowBottom />
                            ) : (
                              <SortArrowTop />
                            )
                          ) : (
                            <SortArrows />
                          )}
                        </Box>
                      )}
                      {column.canResize && (
                        <div
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          {...column.getResizerProps()}
                          className={`${classes.resizer} ${
                            column.isResizing ? classes.isResizing : ''
                          }`}
                        />
                      )}
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className={classes.body}>
          {page.map((row, i) => {
            const currentRow = row as UseExpandedRowProps<T> &
              Row<T> &
              UseRowSelectRowProps<T>;
            prepareRow(currentRow);
            const nextRow = rows[i + 1] as UseExpandedRowProps<T> & Row<T>;
            const isLastInSubGroup =
              currentRow.depth > 0 && nextRow && nextRow.depth === 0;
            const background = (() => {
              if (currentRow.depth === 0) {
                return row.index % 2 ? colors.white : colors.grey15;
              }
              return row.index % 2 ? colors.grey15 : colors.white;
            })();
            return (
              <div key={row.id}>
                <div
                  {...row.getRowProps({
                    style: {
                      background,
                    },
                  })}
                  className={`${classes.tr} ${
                    currentRow.depth > 0 ? classes.subTr : ''
                  } ${isLastInSubGroup ? classes.lastSub : ''} ${
                    currentRow.isSelected ? classes.selectedRow : ''
                  }`}
                  onClick={e => {
                    const el = e.target as HTMLElement;
                    const isRowClickNotFromMenu =
                      el.closest(`.${classes.tr}`) &&
                      !el.closest('.inline-menu-row') &&
                      !el.closest('.row-unclickable');
                    if (isRowClickNotFromMenu) {
                      onRowClick?.(row.id);
                    }
                  }}
                >
                  {row.cells.map(cell => {
                    const isSubSlection =
                      cell.column.id === SELECTION_CELL_ID &&
                      (cell.row as UseExpandedRowProps<T> & Row<T>).depth > 0;
                    const isRowUnClickable =
                      cell.column.id === SELECTION_CELL_ID ||
                      cell.column.id === EXPANDER_CELL_ID;
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={`${classes.td} ${
                          isRowUnClickable ? 'row-unclickable' : ''
                        }`}
                      >
                        {isSubSlection ? '' : cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
                {expandedContentRender && expandedContentRender(row)}
              </div>
            );
          })}
        </div>
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        width="100%"
        mt={1.25}
      >
        <Box display="flex" alignItems="center">
          <PageSizeSelector
            pageSize={pageSize}
            pageSizes={[10, 20, 25]}
            onChange={size => {
              setPageSize(size);
            }}
          />
          <PageIndicator
            align="left"
            page={pageIndex}
            totalResults={serverStatus ? serverStatus.resultCount : rows.length}
            pageSize={pageSize}
          />
          <PaginationUI
            color="primary"
            count={pageCount}
            shape="rounded"
            page={pageIndex + 1}
            onChange={(_, newPage) => {
              gotoPage(newPage - 1);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

Table.defaultProps = {
  data: [],
};
